import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components";
import { Container } from "reactstrap";
import { Link } from "gatsby";
import LinkButtonSecondary from "../components/buttons/LinkButtonSecondary";

import bannerSoftware from "../images/banner/bannerSoftware.png"

import simufact1 from "../images/softwareimage/simufact1.png"
import simufactA from "../images/softwareimage/simufact0A.jpg"
import simufactB from "../images/softwareimage/simufact0B.jpg"
import simufact01 from "../images/softwareimage/simufact01.png"
import simufact02 from "../images/softwareimage/simufact02.png"
import simufact03 from "../images/softwareimage/simufact03.png"
import simufact04 from "../images/softwareimage/simufact04.png"
import simufact05 from "../images/softwareimage/simufact05.png"
import simufact06 from "../images/softwareimage/simufact06.png"

import efficient from "../images/icon/efficient-icon.png";
import quality from "../images/icon/quality-icon.png";
import cost from "../images/icon/cost-icon.png";
import tech from "../images/icon/tech-icon.png";

import { FaWhatsapp } from "react-icons/fa";
import ContactBar from "../components/ContactBar";

const BannerSection = styled.div`
  height: 50vh;
  position: relative;
  .bannerImage{
    background-image: url(${bannerSoftware});
    background-size: cover;
    background-color: #0f0f0f;
    height: 100%;
    // position: relative;
  }
  .bannerText{
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
`
const Section = styled.div`
  padding: 60px 0;
`
const TitleFlex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  .titlehead{
    flex: 2;
    padding: 20px;
    P{
      line-height: 30px;
    }
    h4{
      text-align: left;
    }
  }
  .titleimage{
    flex: 1;
  }
`
const FeatureBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  .textbox{
    padding: 0 20px;
  }
  .imagebox{
    padding: 0 20px;
    img{
      width: 300px;
      height: auto;
      margin-bottom: 50px;
    }
  }
`

const BenefitBox = styled.div`
  h5{
    color: #fff;
    text-align: center;
    font-size: 20px;
  }
  .benefitboxes{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    color: #fff;
    .boxitem{
      flex: 1;
      padding: 15px 5px;
      margin: 5px;
      // border: 1px solid #000;
      border-radius: 5px;
      text-align: center;
      img{
        width: 100px;
        height: auto;
      }
      p{
        margin-bottom: 5px;
        font-weight: bold;
      }
      span{
        font-size: 14px;
      }
    }
  }
`
const ImageBoxes = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  .imagediv{
    flex: 1;
  }
`

const simufactImages = [simufact01, simufact02, simufact03, simufact04, simufact05, simufact06]

const SoftwarePage = () => {
  
  const email = "3dprint@3dgens.com";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(email);
    alert("Email Copied to Clipboard")
  };
  return (
    <Layout>
      <SEO title="3D Metal Printer" />
      <BannerSection>
        <div className="bannerImage"></div>
        <div className="bannerText">
          <h3>
            Software
          </h3>
          <p>
           For Your Digital End-to-End Manufacturin Workflow
          </p>
        </div>
      </BannerSection>
      <Section>
        <Container>
          <h3>
            Seamlessly operate your 3D Systems printers with software
          </h3>
          <TitleFlex>
            <div className="titleimage">
              <img src={simufact1} /> 
            </div>
            <div className="titlehead">
              <h4>
                Simufact: Additive Manufacturing Software
              </h4>
              <p>
                Unlock the true potential of additive manufacturing with Simufact software.<br/>
                Our cutting-edge technology empowers engineers and manufacturers to optimize their <br/>
                additive manufacturing processes, ensuring superior quality, reliability, <br/>
                and efficiency every step of the way.
              </p>
            </div>
          </TitleFlex>
          <FeatureBox>
            <div className="textbox">
              <h5>
                Key Features
              </h5>
              <ul>
                <li>
                  Process Simulation <br/>
                  Simulate the entire additive manufacturing process, from design to production, 
                  to identify and mitigate potential issues before they occur.
                </li>
                <li>
                  Material Optimization <br/>
                  Maximize material usage and performance by exploring various material options and their properties.
                </li>
                <li>
                  Print Preparation <br/>
                  Streamline your workflow with advanced print preparation tools, 
                  ensuring seamless integration with your existing manufacturing processes.
                </li>
                <li>
                  Quality Assurance <br/>
                  Verify the quality of your prints with comprehensive quality assurance tools, 
                  ensuring compliance with industry standards and regulations
                </li>
                <li>
                  Cost Analysis: Evaluate the cost-effectiveness of your additive manufacturing projects with detailed cost analysis features.
                </li>
              </ul>
            </div>
            <div className="imagebox">
              <img src={simufactA}/>
              <img src={simufactB}/>
            </div>
          </FeatureBox>
        </Container>
      </Section>
      <Section style={{backgroundColor:"#224356"}}>
        <Container>
          <BenefitBox>
            <h5>
              Benefits
            </h5>
            <div className="benefitboxes">
              <div className="boxitem">
                <img src={efficient} />
                <p>
                  Increased Efficiency
                </p>
                <span>
                  Optimize your additive manufacturing processes to minimize waste, reduce production time, and maximize productivity.
                </span>
              </div>
              <div className="boxitem">
                <img src={quality} />
                <p>
                  Enhanced Quality
                </p>
                <span>
                  Maintain print quality, meeting customer demands, and ensuring consistency and satisfaction.
                </span>
              </div>
              <div className="boxitem">
                <img src={cost} />
                <p>
                  Cost Savings
                </p>
                <span>
                  Identify cost-saving opportunities and optimize material usage to maximize your return on investment.
                </span>
              </div>
              <div className="boxitem">
                <img src={tech} />
                <p>
                  Future-Proof
                </p>
                <span>
                  Stay ahead of the competition with cutting-edge technology and continuous updates and improvements to our software.
                </span>
              </div>
            </div>
          </BenefitBox>
        </Container>
      </Section>
      <Section>
        <ImageBoxes>
          {simufactImages.map((image) => (
            <div className="imagediv">
              <img src={image} />
            </div>
          ))}
        </ImageBoxes>
      </Section>
      <ContactBar/>
    </Layout>
  )
}

export default SoftwarePage
